<template>
  <div class="home grey lighten-5">
    <Navbar/>
    <v-container class="fill-height-to-nav" fluid>
      <v-row align="center" justify="center">
        <v-col class="col-sm-8 col-md-6 col-lg-4">
          <v-form class="text-right" ref="form" v-model="valid" >
            <v-text-field
              ref="campaignId"
              v-model="campaignId"
              :rules="required"
              label="Kampagnen-Id"
              required
              outlined
            ></v-text-field>

            <v-text-field
              ref="campaignName"
              v-model="campaignName"
              :rules="required"
              label="Kampagnen-Name"
              required
              outlined
              tile
            ></v-text-field>

            <v-text-field
              ref="customerId"
              v-model="customerId"
              :rules="required"
              label="Kunde"
              required
              outlined
            ></v-text-field>

            <v-btn outlined tile depressed x-large color="blue-grey" class="mr-4 mt-4 white--text" @click="reset">Zurücksetzen</v-btn>
            <v-btn tile depressed x-large :disabled="!valid" color="teal" class="mt-4 white--text" @click="submit">Abschicken</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <div><v-alert text :color="type" class="white--text text-center mt-4" v-if="type" v-html="alertMessage"></v-alert></div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { Auth } from 'aws-amplify'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    Navbar
  },
  data: () => ({
    alignments: [
      'start',
      'center',
      'end',
    ],
    valid: true,
    campaignId: '',
    campaignName: '',
    customerId: 'Betano',
    required: [
      v => !!v || 'Dies ist ein Pflichtfeld!',
    ],
    type: null,
    elapse: null,
    alertMessage: '',
    alert: {
      success: {
        text: 'Die Daten wurden erfolgreich übermittelt!',
        icon: '<span>&check;</span>'
      },
      error: {
        text: 'Das hat leider nicht geklappt.',
        icon: '<span>&cross;</span>'
      },
    }
  }),
  methods: {
    async submit () {
      const currentSession = await Auth.currentSession()
      const jwtToken = currentSession.accessToken.jwtToken
      const url = process.env.VUE_APP_API_GATEWAY_PATH

      if(this.$refs.form.validate()){
        const postData = {
          campaignId: this.$refs.campaignId.value,
          campaignName: this.$refs.campaignName.value,
          customer: this.$refs.customerId.value,
        }

        const axiosConfig = {
          headers: {
              Authorization: jwtToken,
          },
          timeout: 5000
        }

        axios.post(url, postData, axiosConfig)
        .then((response) => {
          if(response.status === 200){
            this.showAlert('success')
          } else {
            this.showAlert('error')
          }
        })
        .catch((error) => {
           // eslint-disable-next-line no-console
          console.error(error)
          this.showAlert('error')
        })
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    showAlert (type) {
      this.alertMessage = this.alert[type].icon + ' ' +this.alert[type].text
      this.type = type

      let timer = this.showAlert.timer
      if (timer) {
        clearTimeout(timer)
      }
      this.showAlert.timer = setTimeout(() => {
          this.type = null
      }, 3000)

      this.elapse = 1
      let t = this.showAlert.t
      if (t) {
        clearInterval(t)
      }

      this.showAlert.t = setInterval(() => {
        if (this.elapse === 3) {
          this.elapse = 0
          clearInterval(this.showAlert.t)
        }
        else {
          this.elapse++
        }
      }, 1000)
    }
  },
}
</script>

<style lang="scss" scoped>
.home, v-container {
  height: 100%;
}

.fill-height-to-nav{
  height: calc(100% - 64px);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.v-alert {
  position: absolute;
  max-width: 100%;
  top: 64px;
  left: 50%;
  transform: translateX(-50%);
}

.v-text-field {
  border-radius: 0;
}
</style>
