import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

import Home from '../views/Home'
import Login from '../components/Login'

const routes = [
  { path: '/', component: Home, meta: { requiresAuth: true} },
  { path: '/login', component: Login }
]

const router = new VueRouter({
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser().then(() => {
      next()
    }).catch(() => {
      next({
        path: '/login'
      });
    });
  }
  next()
})

export default router