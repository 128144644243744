import Vue from 'vue'
import VueRouter from 'vue-router'
import Amplify from 'aws-amplify'
import App from './App'
import router from './router/router'
import vuetify from '@/plugins/vuetify'
import '@aws-amplify/ui-vue'
import 'vuetify/dist/vuetify.min.css'

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_OuYIZKEJh',
    userPoolWebClientId: '1mepu9rhiucu7j6viicfapg38v',
  },
})

Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')