<template>
  <div class="login">
    <amplify-authenticator>
      <amplify-sign-in slot="sign-in" :hide-sign-up="true"
        username-alias="email">
      </amplify-sign-in>
    </amplify-authenticator>
  </div>
</template>

<script>

export default {
  name: 'login'
}
</script>

<style>
.login {
  margin: 0 auto;
  width: 460px;
}
</style>