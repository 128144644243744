<template>
    <v-toolbar >
        <v-spacer></v-spacer>
        <div class='sign-out'>
            <amplify-sign-out v-if="signedIn" button-text="Logout"></amplify-sign-out>
        </div>
    </v-toolbar>
</template>

<script>
import { Auth, Hub } from 'aws-amplify'

export default {
  name: 'navbar',
  data() {
    return {
      signedIn: false
    }
  },
  beforeCreate() {
    Hub.listen('auth', data => {
      const { payload } = data
      if (payload.event === 'signOut') {
        this.$router.push('/login')
        this.signedIn = false
      }
    })
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true
      })
      .catch(() => this.signedIn = false)
  }
}
</script>

<style lang="scss" scoped>
</style>